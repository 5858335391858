<template>

<div class="context" v-on:click="expanded = !expanded" v-tooltip="(count && !expanded) ? 'Click to switch convention' : false" :class="{'has-contexts': count}">

	<template v-if="$convention">

		<div class="context-logo" v-bgimage="$convention.logo"><app-icon v-if="!$convention.logo" icon="convention" /></div>

		<div class="context-text">
			{{ $convention.fullname }}<small>{{ $convention.start | formatDate('dddd Do MMMM') }} - {{ $convention.end | formatDate('dddd Do MMMM') }}</small>
		</div>

	</template>

	<template v-if="$organisation && !$convention">

		<div class="context-logo" v-bgimage="$organisation.logo"><app-icon v-if="!$organisation.logo" icon="organisation" /></div>

		<div class="context-text">
			{{ $organisation.name }}<small>Organization</small>
		</div>

	</template>

	<app-icon icon="caret-down" class="context-caret" v-if="!expanded && count" />
	<app-icon icon="close" class="context-caret" v-if="expanded && count" />

	<div class="context-options" v-if="expanded && $convention">

		<div class="context-options-item" v-on:click.stop="onConventionClick(convention.organisation, convention.slug)" v-for="convention in conventions" :key="convention.id">

			<div class="context-options-item-logo" v-bgimage="convention.logo"><app-icon v-if="!convention.logo" icon="convention" /></div>
			<div class="context-options-item-name">{{ convention.fullname }}</div>
			<div class="context-options-item-date">{{ convention.start | formatDate('dddd Do MMMM') }} - {{ convention.end | formatDate('dddd Do MMMM') }}</div>

		</div>

	</div>

	<div class="context-options" v-if="expanded && !$convention">

		<div class="context-options-item" v-on:click.stop="onOrganisationClick(org.slug)" v-for="org in organisations" :key="org.id">

			<div class="context-options-item-logo" v-bgimage="org.logo"><app-icon v-if="!org.logo" icon="organisation" /></div>
			<div class="context-options-item-name">{{ org.name }}</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			expanded: false
		}

	},

	computed: {

		count: function() {

			return (this.$convention) ? this.$store.getters['session/conventions'].length > 1 : this.$store.getters['session/organisations'].length > 1

		},

		conventions: function() {

			var id = this.$store.getters['context/convention'].id || 0

			return this.$_.filter(this.$store.getters['session/conventions'], function(convention) {

				return convention.id !== id

			}.bind(this))

		},

		organisations: function() {

			var id = this.$store.getters['context/organisation'].id || 0

			return this.$_.filter(this.$store.getters['session/organisations'], function(organisation) {

				return organisation.id !== id

			}.bind(this))

		}

	},

	methods: {

		onOrganisationClick: function(org) {

			this.expanded = false

			this.$router.push({
				name: 'Organisation.Dashboard',
				params: {
					organisation: org
				}
			})

		},

		onConventionClick: function(org, con) {

			this.expanded = false

			org = this.$_.findWhere(this.$store.getters['session/organisations'], {
				id: org
			}).slug

			this.$router.push({
				name: 'Convention.Dashboard',
				params: {
					organisation: org,
					convention: con
				}
			})

		}

	}

}

</script>

<style scoped>

.context {
	color: #fff;
	font-size: 32px;
	padding: 0px 20px;
	font-weight: 400;
	height: var(--head-height);
	display: flex;
	align-items: center;
	flex-grow: 1;
	user-select: none;
}

.context.has-contexts {
	cursor: pointer;
}

.is-tablet .context {
	font-size: 24px;
	padding: 0px 10px;
}

.is-mobile .context {
	font-size: 20px;
	padding: 0px 0px;
}

.context-logo {
	width: 64px;
	height: 64px;
	background-size: contain;
	background-color: #fff;
	border-radius: 10px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border: 4px solid #ffffff;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.context-logo >>> i {
	font-size: 40px;
	color: #ffbe7a;
}

.is-mobile .context-logo {
	width: 32px;
	height: 32px;
}

.context-text {
	flex-grow: 1;
}

.context-text small {
	font-weight: 300;
	font-size: 16px;
	margin-top: 4px;
	display: block;
}

.is-tablet .context-text small {
	font-size: 14px;
}

.is-mobile .context-text small {
	font-size: 12px;
}

.context-caret {
	color: #fff;
	font-size: 16px;
	flex-shrink: 0;
}

.context-options {
	position: fixed;
	top: var(--head-height);
	left: 320px;
	background-color: #2f3a4a;
	width: calc(100% - 320px);
}

.context-options-item {
	display: flex;
	min-height: 40px;
	padding: 4px 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.context-options-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.context-options-item-logo {
	width: 32px;
	height: 32px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin-right: 20px;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.context-options-item-logo >>> i {
	font-size: 20px;
	height: 32px;
	line-height: 24px;
	color: #ffbe7a;
}

.context-options-item-name {
	flex-grow: 1;
	font-size: 16px;
	line-height: 16px;
	display: flex;
	align-items: center;
}

.context-options-item-date {
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	opacity: 0.6;
}

</style>
